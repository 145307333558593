import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Wysiwyg from '@/components/Wysiwyg'

interface HomeData {
  data: {
    menu?: any
    quickmenu?: any
    wp: {
      options: {
        content: string
      }
    }
  }
}

const NotFound = ({ data }: HomeData) => {
  // Page data
  const { menu, quickmenu, wp } = data

  return (
    <Layout nav={menu} quickNav={quickmenu} showHero={false}>
      <section className="flex justify-center items-center">
        <Wysiwyg>{wp.options.content}</Wysiwyg>
      </section>
    </Layout>
  )
}

export const NotFoundQuery = graphql`
  query NotFound {
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
    wp {
      options: crbThemeOptions {
        content: gwNotfoundContent
      }
    }
  }
`

export default NotFound
